.entity-addNew-botton,
.entity-addNew-botton-white {
    border: none;
    background-color: var(--color-gray-blue);
    border-radius: 7px;
    cursor: pointer;
    padding: 12px;
    height: 44px;
    width: 50%;
    max-width: 200px;
    color: white;
    font-size: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.entity-addNew-botton-white {
    background-color: white;
    border: 1px solid var(--color-gray-blue);
    color: var(--color-gray-blue);
}

.entityCard-container:hover {
    opacity: 0.75;
}

.entity-addNew-botton:hover,
.entity-addNew-botton-white:hover {
    background-color: var(--color-gray-blue-dark);
}

.entity-addNew-botton-white:hover {
    background-color: var(--color-gray-ligth);
}

.entitiy-addModal-container>button {
    background-color: var(--color-gray-blue);
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
    padding: 12px;
    width: 100%;
}

.entitiy-addModal-container>button:disabled {
    background-color: var(--color-gray-blue-ligth);
    cursor: not-allowed;
}

.entitiy-addModal-container section {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
}

.entitiy-addModal-container section>* {
    width: 100%;
}

.entitiy-addModal-container section * {
    color: var(--color-gray-dark);
    font-weight: 500;
}

.entitiy-addModal-container h6 {
    font-size: 12px;
}

.entity-importClients-container section * {
    font-size: 14px;
}

.entity-importClients-container h4 {
    font-size: 20px;
    margin: 20px 0;
}

.entity-importClients-container section {
    display: flex;
    flex-direction: column;
}

.entity-backToSection-container {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    align-items: center;
}

.entity-backToSection-container>a>img {
    width: 30px;
    height: 30px;
    rotate: 270deg;
    background-color: var(--color-gray-blue);
    border: 1px solid var(--color-gray-blue);
    border-radius: 9px;
    padding: 5px;
    cursor: pointer;
}

.entity-backToSection-container>a>img:hover {
    background-color: var(--color-gray-blue-dark);
    border: 1px solid var(--color-gray-blue-dark);
}

.entity-backToSection-container h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.entity-backToSection-container h4 {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
}

.entity-button-blue,
.entity-button-blueWhite,
.entity-button-red {
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    width: 100px;
}

.entity-button-blue {
    border: none;
    background-color: var(--color-gray-blue);
    color: white;
}

.entity-button-blue:hover {
    background-color: var(--color-gray-blue-dark);
}

.entity-button-blue:focus {
    background-color: var(--color-gray-blue-ligther);
}

.entity-button-blue:disabled {
    background-color: var(--color-gray-blue);
    border: none;
    padding: 5px;
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

.entity-button-blueWhite {
    border: 2px solid var(--color-gray-blue);
    background-color: white;
    color: var(--color-gray-blue);
    padding: 3px 5px;
}

.entity-button-blueWhite:hover {
    background-color: var(--color-gray-ligther);
    box-shadow: none;
}

.entity-button-red {
    background-color: var(--color-red);
    border: none;
    color: white;
}

.entity-button-red:focus {
    opacity: 0.75;
}

.entity-cards-container {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    gap: 10px;
    justify-content: space-between;
    margin: 20px auto;
}

.entityCard-container {
    background-color: white;
    border: 1px solid var(--color-gray-ligth);
    border-radius: 6px;
    cursor: pointer;
    padding: 15px 20px 10px 20px;
}

.entityCard-container h3 {
    font-size: 16px;
    font-weight: normal;
    color: var(--color-gray-blue);
    margin: 0;
    margin-bottom: 3px;
    text-transform: uppercase;

    max-height: 32px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entityCard-container h4 {
    font-size: 12px;
    font-weight: normal;
    color: var(--color-gray);
    text-transform: capitalize;

    max-height: 32px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entity-filters-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.entity-filters-container section {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.entity-contact-container .mantine-Input-input {
    font-size: 14px;
    padding: 2px 7px;
}

.entity-contact-container .mantine-NativeSelect-root {
    width: 100%;
}

.entity-contact-container>div {
    display: flex;
    margin-bottom: 17px;
    align-items: center;
}

.entity-contact-container>div:has(select),
.entity-contact-container>div:has(input) {
    margin-bottom: 5px;
}

.entity-contact-container h3,
.entity-contact-container h4 {
    margin: 0;
    font-size: 14px;
    min-width: 50%;
}

.entity-contact-container>div:last-child {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.entity-contact-container h4 {
    font-weight: 300;
    color: var(--color-gray-dark);
}

.entity-contact-container h5,
.entity-contact-container h6 {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    margin-top: 5px;
    color: var(--color-gray-dark);
}

.entity-contact-container h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}

.entity-contact-container>section {
    background-color: white;
    border: 1px solid var(--color-gray-ligth);
    border-radius: 6px;
    margin: 10px 0 13px 0;
}

.entity-contact-container>section section img {
    margin-left: 7.5px;
    cursor: pointer;
}

.entity-contact-container section div {
    border-bottom: 1px solid var(--color-gray-ligther);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.entity-contact-container section div:last-of-type {
    border-bottom: none;
}

.entity-contact-container section div:last-of-type:has(img) {
    cursor: pointer;
    padding: 5px;
}

.entity-contact-container section div:last-of-type div {
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 5px;
    gap: 5px;
}

.entity-contact-container section div:last-of-type div img {
    height: 15px;
}

.entity-contact-container section div:last-of-type div h6 {
    color: var(--color-gray-blue);
    font-size: 14px;
    margin: 0;
}

.entity-entire-container {
    display: flex;
    flex-direction: column;
}

.mantine-Modal-overlay {
    z-index: 1 !important;
}

@media (min-width: 744px) and (max-width: 1298px) {
    .entity-contact-container {
        width: 400px;
    }

    .entity-contact-container .mantine-NativeSelect-root {
        padding: 0 7px 0 0;
    }

    .entity-cards-container {
        grid-template-columns: repeat(2, 326px);
    }

    .entity-filters-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .entity-filters-container section {
        margin-top: 0;
    }

    .entity-entire-container {
        flex-direction: row;
    }

    .modalBox-container {
        width: 375px !important;
    }
}


@media (min-width: 1298px) {
    .entity-contact-container {
        width: 400px;
    }

    .entity-contact-container .mantine-NativeSelect-root {
        padding: 0 7px 0 0;
    }

    .entity-cards-container {
        grid-template-columns: repeat(4, 292px);
    }

    .entity-filters-container {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .entity-filters-container section {
        margin-top: 0;
    }

    .entity-entire-container {
        flex-direction: row;
        gap: 50px;
    }

    .modalBox-container {
        width: 450px !important;
    }
}