.products {
	display: grid;
	width: 360px;
	margin: auto;
	margin-top: 60px;
}

.productos-sin-accesos_rapidos {
	display: flex;
	flex-direction: column;
}

.productos-contenedor-central {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 30px;
}

.productos-contenedor-barra_busqueda {
	margin: 10px 16px 20px 16px;
	max-width: 732px;
	width: 330px;

	display: flex;
	align-items: flex-end;
}

.productos-contenedor-barra_busqueda>button {
	height: 36px;
	position: relative;
	left: -2px;


	background: var(--color-gray-blue);
	border: none;
	border-radius: 0 7px 7px 0;

	color: white;
	font-size: 16px;
	font-weight: 500;
	height: 36px;
	padding: 0 15px;
}

.productos-contenedor-barra_busqueda>button:hover {
	background-color: white;
	color: var(--color-gray-blue);
	border: 1px solid var(--color-gray-ligth);
}


.productos-contenedor-barra_busqueda>div {
	width: inherit;
}

.productos-contenedor-barra_busqueda .m_8fb7ebe7:focus,
.productos-contenedor-barra_busqueda .m_8fb7ebe7:focus-within {
	--input-bd: var(--color-gray-blue);
}

.products-cards-container {
	display: grid;
	grid-template-columns: repeat(1, auto);
	gap: 15px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.producto-icono-recomendaciones {
	position: fixed;
	bottom: 70px;
	right: 13px;

	border-radius: 50%;
	box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
	background: var(--color-green);
	color: white;
	cursor: pointer;
	font-size: 18px;
	font-weight: 700;
	height: 60px;
	padding: 18px 25px;
	z-index: 10;
}

.producto-recomendaciones-seccion-recomendaciones {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(4px);
}

.producto-recomendaciones-seccion-iconos {
	display: flex;
	justify-content: flex-end;
	padding: 20px;
}

.producto-recomendaciones-seccion-iconos img {
	position: relative;
	z-index: 10;
	width: 13px;
}

.producto-recomendaciones-seccion-iconos>div {
	color: white;
	font-size: 0.875rem;
	font-weight: bold;
}

.pagination-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.pagination-container *:disabled {
	background-color: rgba(0, 0, 0, 0.075) !important;
}

.pagination-container .mantine-Group-root {
	--group-gap: calc(0.35rem * var(--mantine-scale)) !important
}

.products-noProducts-display {
	display: flex;
}

.product-ARS-currency-theme {
	border-top: 2px solid var(--color-gray-blue) !important;
	color: var(--color-gray-blue) !important;
}

.products-totalQuantity {
	width: 100%;
	text-align: end;
	font-size: 14px;
	color: var(--color-gray-dark);
	font-weight: 400;
	align-content: center;
	margin-bottom: 20px;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.products {
		width: 100%;
	}

	.productos-sin-accesos_rapidos {
		flex-direction: row;
		justify-content: space-evenly;
		gap: 10px;
	}
}


@media (min-width: 1298px) {

	.productos-sin-accesos_rapidos {
		flex-direction: row;
		justify-content: space-evenly;
		gap: 10px;
	}

	.products {
		width: 100%;
		padding-top: 44px;
		margin-top: 90px;
	}

	.products .menuItems-container {
		display: none;
	}

	.producto-icono-recomendaciones {
		display: none;
	}

	.producto-recomendaciones-seccion {
		display: flex;
		flex-direction: column;
		margin-top: 4px;
	}

	.productos-contenedor-barra_busqueda {
		width: 100%;
	}


	.productos-contenedor-barra_busqueda {
		margin: -26px 16px 20px 16px;
	}


	.productos-contenedor-barra_busqueda>button {
		height: 45px;
	}

}

@media (min-width: 1000px) {
	.products-cards-container {
		width: 650px;
		grid-template-columns: repeat(2, auto);
	}
}

@media (min-width: 1775px) {
	.products-cards-container {
		width: 984px;
		grid-template-columns: repeat(3, auto);
	}
}