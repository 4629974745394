.creationModal-container {
    padding: 20px;
}

.creationModal-container button {
    background-color: var(--color-gray-blue);
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px;
    width: 100%;
}

.creationModal-container button:disabled {
    background-color: var(--color-gray-blue-ligth);
    cursor: not-allowed;
}

.creationModal-container input {
    color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-ligther);
    border-radius: 6px;
    font-size: 14px;
    padding: 3px;
    width: 145px;
}

.creationModal-container input:last-of-type {
    width: 100%;
}

.creationModal-container section {
    display: flex;
    justify-content: space-between;
    width: auto;
    gap: 10px;
}