.MuiSlider-thumbColorPrimary {
	color: white !important;
}

.filtros-tarjeta-filtro {
	overflow: overlay;
	background-color: white;
	border-radius: 5px;
	padding: 10px;
	border: 1px solid var(--color-gray-ligther);
	margin-top: 10px;
}

.filtros-tarjeta-filtro:hover {
	opacity: 0.75;
	background-color: var(--color-gray-ligther);
}

.filtros-tarjeta-filtro:has(section):hover {
	opacity: 1;
	background-color: white;
}

.filtros-tarjeta-filtro div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.filtros-tarjeta-filtro div h5 {
	margin: 0;
	color: var(--color-gray-dark);
	font-weight: lighter;
	font-size: 0.8rem;
}

.filtros-tarjeta-filtro div img {
	display: none;
}

.filtros-tarjeta-filtro section {
	position: absolute;
	background-color: white;
	z-index: 100;
	border: 1px solid var(--color-gray-ligther);
	box-shadow: -2px 4px 12px 0 rgba(0, 0, 0, 0.25);
	width: 140px;
	left: 50%;
	top: 88%;
	flex-direction: column;
}

.filtros-tarjeta-opciones-modal section {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.filtros-tarjeta-filtro div {
	color: var(--color-gray);
	font-size: 0.8rem;
	margin: 0;
}

.filtros-tarjeta-opciones-modal p,
.filtros-tarjeta-filtro p {
	margin: 0;
	font-size: 0.8rem;
}

.car-filters-container {
	width: 100%;
	margin: auto;
	margin-top: 15px;
}

.car-filters-container h6 {
	background-color: var(--color-gray-blue);
	cursor: pointer;
	width: 100%;
	color: white;
	border-radius: 7px;
	font-weight: 400;
	font-size: 16px;
	margin: auto;
	padding: 10px;
	text-align: center;
}

.car-filters-container h6:hover {
	background-color: var(--color-gray-blue-ligth);
	color: var(--color-gray-blue);
}

.car-filters-container>*,
.car-filters-container .mantine-Accordion-item {
	background-color: white;
	border-color: var(--color-gray-ligth);
}

.car-filters-container .mantine-Input-wrapper>* {
	text-transform: capitalize;
}

.filters-priceSlider p,
.car-filters-container .mantine-Switch-label,
.car-filters-container .mantine-Accordion-label {
	font-size: 12px;
	font-weight: 500;
	margin: 0;
	color: var(--color-gray);
}

.filters-priceSlider p,
.car-filters-container .mantine-Switch-label {
	font-weight: 400;
	font-size: 14px;
}

.car-filters-container section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
}

.car-filters-container section h4 {
	color: var(--color-gray);
	font-size: 14px;
	font-weight: 400;
	padding: 0 15px 0 0;
	margin: 0;
}

.car-filters-container .mantine-Accordion-content {
	display: flex;
	flex-direction: column;
	gap: 13px;
}

.car-filters-container .mantine-Switch-body {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.filters-priceSlider {
	display: flex;
	flex-direction: column;
}

.filters-priceSlider>div {
	display: flex;
	justify-content: space-between;
	width: 95%;
	margin: auto;
}

.car-filters-container .mantine-Select-label,
.car-filters-container .mantine-NativeSelect-label {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin-left: 4px;
}

.filters-priceSlider h4,
.filters-dates h4,
.filters-numbers h4 {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin: 0;
	padding-bottom: 5px;
	padding-left: 5px;
}

.filters-numbers>div,
.filters-dates div {
	display: flex;
	gap: 15px;
}

.filters-dates div>div {
	width: 100%;
}

.filters-dates div .m_82577fc2 {
	justify-content: right !important;
}

.mantine-MultiSelect-option span {
	text-transform: capitalize;
}

label[for="filter-switch-dollar"] .mantine-Switch-track-aa {
	background-color: var(--color-green) !important;
	border: 1px solid var(--color-green) !important;
	color: white !important;
}

label[for="filter-switch-isSupplier"] .mantine-Switch-track-aa {
	background-color: var(--color-gray-blue) !important;
	border: 1px solid var(--color-gray-blue) !important;
	color: white !important;
}

.car-filters-container .mantine-Switch-body:has(input:checked) .mantine-Switch-track-aa {
	background-color: var(--color-gray-blue) !important;
	border: 1px solid var(--color-gray-blue) !important;
	color: white !important;
	cursor: pointer !important;
}

.car-products-left-container {
	display: flex;
	flex-direction: column;
	width: 316px;
	margin: auto;
	margin-top: 10px;
}

.car-products-left-container .m_38a85659 {
	width: 288px !important;
}

.car-filters-container select {
	width: 100%;
}

@media (min-width: 744px) {
	.filtros-tarjeta-filtro {
		border-radius: 9.3px;
	}

	.filtros-tarjeta-filtro div {
		justify-content: space-between;
		padding: 0 10px;
	}

	.filtros-tarjeta-filtro div img {
		display: block;
		height: 20px;
		width: 20px;
	}

	.filtros-tarjeta-filtro section {
		position: static;
		border: 0;
		box-shadow: none;
	}

	.filtros-tarjeta-opciones-check {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 10px;
	}

	.car-filters-container .mantine-Accordion-item {
		width: 100%;
	}

	.car-products-left-container {
		width: 300px;
		margin: initial;
		margin-bottom: 20px;
	}

	.car-products-left-container>div {
		width: inherit;
	}
}

@media (min-width: 1298px) {
	.car-filters-container {
		margin: unset;
		padding-top: 5px;
	}

	.car-filters-container .mantine-Accordion-item {
		width: 100%;
	}

	.car-products-left-container {
		width: 300px;
		margin: initial;
		margin-bottom: 20px;
	}

	.car-products-left-container>div {
		width: inherit;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.car-filters-container .mantine-Accordion-content {
		padding: 15px
	}
}