
.labelingManager-button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 167px;
    margin: 10px 0;
    border-radius: 7px;
    border: none;
    background-color: var(--color-gray-blue-dark);
    padding: 10px 0;

    color: white;
    font-size: 14px;
}

.labelingManager-button:hover {
    background-color: var(--color-gray-blue);
}

.labelingManager-button:focus {
    background-color: var(--color-gray-blue-ligther);
}

.labelingManager-container>div:nth-child(3)>section {
    margin-bottom: 20px;
}

.labelingManager-container>section {
    margin-bottom: 30px;
}

.labelingManager-container section {
    display: flex;
    gap: 7.6px;
}

.labelingManager-container section:last-child {
    justify-content: flex-end;
}

.labelingManager-container section p {
    color: var(--color-gray-dark);
    font-size: 0.75rem;
    font-weight: 300;
    margin: 0;
}

.labelingManager-list-container{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    margin-bottom: 50px;
}

.labelingManager-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.labelingManager-list-item>span {
    width: 100px;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: right;
    color: #3c4d66;
}

.labelingManager-list-item div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: white;
    border: 1px solid var(--color-gray-ligth);
    border-radius: 6px;

    color: var(--color-gray-dark);
    font-size: 0.8rem;
    font-weight: 300;

    height: 45px;
    padding: 10px;
    width: 100%;
}

.labelingManager-list-item h5 {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

.labelingManager-list-item input {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 300;
    width: 130px;

    border: none;
    border-bottom: 1px solid #0054ea;
    color: var(--color-gray-dark);
}

.labelingManager-list-item section input:nth-child(2) {
    width: 35px;
    text-align: flex-end;
}

.labelingManager-list-item section {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (min-width: 744px) and (max-width: 1298px) {
    .labelingManager-list-container{
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
    }

    .labelingManager-list-item {
        width: 315px;
    }

    .labelingManager-list-options_container {
        right: 20%;
    }
}

@media (min-width: 1298px) {
    .labelingManager-list-container{
        grid-template-columns: repeat(3,1fr);
    }

    .labelingManager-list-item {
        width: 395px;
    }

    .labelingManager-list-options_container {
        left: 40%;
    }

    .labelingManager-list-item input {
        width: 170px;
    }

    .labelingManager-list-item section input:nth-child(2) {
        width: 50px;
    }

}