:root {
	--color-gray-blue-dark: #3c4d66;
	--color-gray-blue: #506584;
	--color-gray-blue-ligth: #98a3b3;
	--color-gray-blue-ligther: #9ab1cc;

	--color-gray-dark: #4d4d4d;
	--color-gray: #7a7a7a;
	--color-gray-ligth: #cacaca;
	--color-gray-ligther: #e3e3e3;

	--color-green: #379c52;

	--color-red: #dd5656;

	background: var(--color-gray-ligther) !important;

	--scrollbar-thumb-color: white;
	--scrollbar-track-color: var(--color-gray-blue-dark);
}

@font-face {
	font-family: "password";
	font-style: normal;
	font-weight: 400;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

body {
	overflow-x: hidden;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--color-gray-ligther) !important;
	font-family: Roboto;
}

h2 {
	font-weight: 300;
	font-size: 19px;
	color: var(--color-gray-dark);
	margin: 0;
}

.bi {
	font-size: 2rem;
}

.contenedor-barra-superior {
	height: 51px;
}

.global-small-img-button {
	width: 30px;
	height: 30px;
	padding: 5px 0;
	border-radius: 5px;
	border: 1px solid var(--color-gray-blue);
	cursor: pointer;
}

.global-small-img-button:hover {
	padding: 4px 0;
}

.mantine-Switch-thumb,
.mantine-Switch-track {
	cursor: pointer !important;
	border: 1px solid var(--color-gray);
}

.mantine-Notification-root {
	border: 1px solid var(--color-gray-blue-dark);
	--notification-color: rgba(60, 77, 102, 1) !important;
}

.mantine-Notification-title {
	color: var(--color-gray-dark) !important;
	font-size: 18px !important;
}

.mantine-Notification-description {
	font-size: 14px !important;
}

.mantine-Notification-root button {
	color: var(--color-gray-blue-dark);
}

.mantine-Modal-inner {
	z-index: 1100 !important;
}

.mantine-Modal-content {
	padding-top: 0px !important;
}

@media (min-width: 744px) and (max-width: 1298px) {
	.contenedor-barra-superior {
		height: 60px;
	}
}

@media (min-width: 1298px) {
	html {
		font-size: 20px;
	}

	.contenedor-barra-superior {
		height: 88px;
	}
}

/* Estilo para navegadores WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
	width: 8px;
	/* Ancho de la barra vertical */
	height: 8px;
	/* Altura de la barra horizontal */
}

::-webkit-scrollbar-track {
	background: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb-color);
	border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(155, 155, 155, 0.7);
}

/* Estilo para Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}

.cuentas-comercial-button_confirm,
.cuentas-comercial-button_undo {
	width: 30px !important;
	padding: 8px;
	border-radius: 6px;
	background-color: transparent;
	color: var(--color-gray-blue);
	border: 1px solid var(--color-gray-blue);
	font-size: 14px;
	font-weight: 500;
}

.cuentas-comercial-button_confirm {
	background-color: var(--color-gray-blue);
	color: white;
	border: none;
}

.cuentas-comercial-button_confirm[disabled] {
	background-color: var(--color-gray-blue-ligth);
}

.cuentas-comercial-button_delete,
.cuentas-comercial-button_edit {
	width: 178px;
	height: 40px;
	margin: 5px 0;
	border-radius: 7px;
	border: none;
	background-color: var(--color-gray-blue);
	color: white;
	font-size: 0.75rem;
	font-weight: 500;
}

.cuentas-comercial-button_delete {
	background-color: var(--color-red);
	border: 1px solid var(--color-red);
	color: white;
}