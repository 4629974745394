.carsMarketValues-circle {
	height: 16px;
	width: 16px;

	background-color: var(--color-gray-blue-dark);
	border-radius: 50%;
}


.carsMarketValues-container h4 {
    color: var(--color-gray-dark);
	font-size: 20px;
	font-weight: 600;
	margin: 0;
}

.carsMarketValues-container section{
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
}

.carsMarketValues-container section img{
    width: 25px;
    height: 25px;
}

.carsMarketValues-container section span{
    background-color: var(--color-gray-blue-dark);
    border-radius: 7px;
    padding: 3px 20px;
}
