.MuiSlider-thumbColorPrimary {
	color: white !important;
}

.filters-container {
	width: 100%;
	margin: auto;
	margin-top: 15px;
}

.filters-container .mantine-Input-wrapper option {
	text-transform: capitalize;
}

.filters-container>*,
.filters-container .mantine-Accordion-item {
	background-color: white;
	border-color: var(--color-gray-ligth);
}

.filters-priceSlider p,
.filters-container .mantine-Switch-label,
.filters-container .mantine-Accordion-label {
	font-size: 12px;
	font-weight: 500;
	margin: 0;
	color: var(--color-gray);
}

.filters-priceSlider p,
.filters-container .mantine-Switch-label {
	font-weight: 400;
	font-size: 14px;
}

.filters-container section {
	display: flex;
	justify-content: space-between;
	gap: 5px;
}

.filters-container .mantine-Accordion-content {
	display: flex;
	flex-direction: column;
	gap: 13px;
}

.filters-container .mantine-Switch-body {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.filters-priceSlider {
	display: flex;
	flex-direction: column;
}

.filters-priceSlider>div {
	display: flex;
	justify-content: space-between;
}

.filters-container .mantine-Select-label,
.filters-container .mantine-NativeSelect-label {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin-left: 4px;
}

label[for="filter-switch-dollar"] .mantine-Switch-track {
	background-color: var(--color-green) !important;
	border: 1px solid var(--color-green) !important;
	color: white !important;
}


label[for="filter-switch-isSupplier"] .mantine-Switch-track {
	background-color: var(--color-gray-blue) !important;
	border: 1px solid var(--color-gray-blue) !important;
	color: white !important;
}

.filters-container .mantine-Switch-body:has(input:checked) .mantine-Switch-track {
	background-color: var(--color-gray-blue) !important;
	border: 1px solid var(--color-gray-blue) !important;
	color: white !important;
	cursor: pointer !important;
}

.products-left-container {
	display: flex;
	flex-direction: column;
}

.products-left-container .m_38a85659 {
	width: 288px !important;
}

.filters-container select {
	width: 100%;
}

@media (min-width: 744px) {
	.filters-container .mantine-Accordion-item {
		width: 100%;
	}

	.products-left-container {
		width: 300px;
	}

	.products-left-container>div {
		width: inherit;
	}
}

@media (min-width: 1298px) {
	.filters-container {
		margin: unset;
		padding-top: 5px;
	}

	.filters-container .mantine-Accordion-item {
		width: 100%;
	}

	.products-left-container {
		width: 330px;
	}

	.products-left-container>div {
		width: inherit;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.filters-container .mantine-Accordion-content {
		padding: 15px
	}
}