.currencyCard {
	color: var(--color-gray-dark);
	display: flex;
	flex-direction: column;
	gap: 5px;
	border-bottom: 1px solid var(--color-gray-ligth);
	padding: 5px 0;
}

.currencyCard-circle {
	height: 16px;
	width: 16px;

	background-color: var(--color-green);
	border-radius: 50%;
}

.currencyCard div {
	display: flex;
	align-items: center;
	gap: 5px;
}

.currencyCard h4 {
	font-size: 18px;
	font-weight: 600;
	margin: 0;
}

.currencyCard h5,
.currencyCard a {
	font-size: 12px;
	font-weight: 500;
	color: var(--color-gray);
	margin: 0;
}

.currencyCard a {
	max-width: 150px;
	height: 16px;
	overflow: hidden;
	text-decoration: none;
}

.currencyCard span {
	font-size: 16px;
	font-weight: 600;
	background-color: var(--color-green);
	border-radius: 10px;
	color: white;
	padding: 2px 7.5px;
	min-width: 75px;
	text-align: center;
}

.currencyCard section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}