.carsMarketValue-container table {
    white-space: nowrap;
    font-size: 12px;
}

.carsMarketValue-filters-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.carsMarketValue-filters-container>div {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.carsMarketValue-filters-container>div>* {
    width: 155px;
}

.carsMarketValue-company-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.carsMarketValue-company-container h5 {
    color: var(--color-gray-blue);
    font-size: 10px;
    font-weight: 200;
    margin: 0;
}

.carsMarketValue-company-container span {
    background-color: white;
    border: 2px solid var(--color-gray-blue);
    border-radius: 7px;
    color: var(--color-gray-blue);
    font-size: 12px;
    font-weight: 600;
    padding: 3px 7px;
    margin-bottom: -5px;

    display: flex;
    gap: 5px;
}

.carsMarketValue-noInfo-container{
    text-align: center;
    padding: 10px;
    width: 100%;
}

.carsMarketValue-noInfo-container h6{
    color: var(--color-gray-dark);
    margin: 0;
}

@media (min-width: 744px) {
    .carsMarketValue-filters-container {
        flex-direction: row;
    }
}

@media (min-width: 1298px) {
    .carsMarketValue-container table {
        white-space: nowrap;
        font-size: 16px;
    }

    .carsMarketValue-filters-container {
        gap: 20px;
    }

    .carsMarketValue-filters-container>div>* {
        width: 250px;
    }

    .carsMarketValue-company-container h5 {
        font-size: 12px;
    }

    .carsMarketValue-company-container span {
        font-size: 16px;
        padding: 5px 10px;
        gap: 10px;
    }
}