.carSelectedProductCard-price-suggested{
	border-top: 2px solid var(--color-gray-blue);
	padding: 5px;
	border-radius: 0 0 10px 10px;
	font-size: 18px;
	font-weight: 500;
	color: var(--color-gray-blue);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.carSelectedProductCard-price-suggested h6{
	font-size: 14px;
	margin: 0;
}

.carSelectedProductCard-price-suggested span {
	font-weight: 600;
}

.quotation-quantity-span {
	color: white;
	background-color: var(--color-gray-blue);
	border-radius: 7px;
	padding: 4px 8px;
	height: fit-content;
	font-size: 12px;
	font-weight: 600;
	line-height: normal;
}


.selectedCarProducts-selects {
	margin-bottom: 10px;
	width: 330px;
}

.selectedCarProduct-container {
	margin: auto;
	margin-top: 85px;
	width: 330px;
	margin-bottom: 20px;
}

.selectedCarProduct-dollar-container select {
	width: 328px;
	background-color: white;
	border-radius: 3px;
	color: var(--color-gray-dark);
	font-size: 14px;
	font-weight: 500;
}

.selectedCarProduct-container option {
	color: black;
	font-size: 14px;
}

.selectedCarProduct-outdated {
	color: var(--color-red);
	font-size: 12px;
	font-weight: 300;
	margin: 0;
}

.selectedCarProduct-description-info {
	font-size: 14px;
	font-weight: 300;
	text-align: justify;
	color: var(--color-gray-dark)
}

.selectedCarProduct-description-info p {
	max-height: calc(1.4em * 5);
	line-height: 1.4em;
	margin-bottom: 5px;
}

.selectedCarProduct-description-info div {
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
	cursor: pointer;
}

.selectedCarProduct-description-info img {
	width: 8px;
}

.selectedCarProduct-description-info h6 {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin: 0;
}

.selectedCarProduct-description-title {
	font-size: 16px;
	font-weight: 400;
	margin: 20px 0 10px;
}

.selectedCarProduct-image {
	max-width: 180px;
	max-height: 180px;
}

.selectedCarProduct-image-container {
	background-color: white;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 40px 10px;
	width: 100%;
	border: 1px solid var(--color-gray-ligth);
	border-radius: 10px;
}

.selectedCarProduct-image-container img {
	cursor: pointer;
}

.selectedCarProduct-images-li {
	list-style-type: none;
	border: 1px solid var(--color-gray-ligth);
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 4px;
	height: 40px;
	width: 40px;
	padding: 2px;
	display: flex;
	justify-content: center;
}

.selectedCarProduct-images-li img {
	max-height: 35px;
	max-width: 35px;
	cursor: pointer;
}

.selectedCarProduct-image-ul {
	display: flex;
	padding: 0;
	gap: 14px;
}

.selectedCarProduct-info-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	text-align: center;
	margin-top: 10px;
	padding: 22px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	min-height: 90px;
}

.selectedCarProduct-info-container span {
	color: var(--color-gray);
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
}

.selectedCarProduct-info-container h4 {
	color: var(--color-gray-blue);
	font-size: 14px;
	font-weight: 600;
}

.selectedCarProduct-info-container>hr {
	width: 57.5px;
	transform: rotate(90deg);
	margin-left: -16px;
	margin-right: -16px;
}

.selectedCarProduct-link-container {
	display: flex;
	background-color: white;
	border: 1px solid var(--color-gray-blue);
	border-radius: 8px;
	margin-top: 15px;
	text-decoration: none;
	padding: 13px;
	width: 100%;
}


.selectedCarProduct-link-container:hover {
	background-color: var(--color-gray-blue-dark);
	border: 1px solid var(--color-gray-blue-dark);
}

.selectedCarProduct-link-container:hover>.selectedCarProduct-link-title {
	color: var(--color-gray-ligther);
}

.selectedCarProduct-link-title {
	color: var(--color-gray-blue);
	font-size: 12px;
	font-weight: bold;
	margin: 0;
	width: inherit;
	text-align: center;
}

.selectedCarProduct-mainInfo-container {
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 190px;
	margin-bottom: 15px;
	padding: 5px;
}

.selectedCarProduct-mainInfo-container>* {
	padding: 10px;
}

.selectedCarProduct-mainInfo-container section {
	border-top: 2px solid var(--color-gray-blue);
	padding: 5px;
	border-radius: 0 0 10px 10px;
	display: flex;
	justify-content: space-around;
	font-size: 16px;
	font-weight: 500;
	margin-top: -10px;
	width: 328px;
	color: var(--color-gray-blue);
	margin-left: -5px;
}

.selectedCarProduct-mainInfo-container section div span:nth-of-type(1) {
	font-weight: normal;
	font-size: 14px;
}

.selectedCarProduct-mainInfo-container section span {
	font-weight: 600;
}

.selectedCarProduct-mainInfo-container section div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.selectedCarProduct-no-categoryLink,
.selectedCarProduct-categoryLink {
	display: flex;
	align-items: center;
	gap: 7px;
	padding: 30px 0 20px 0;
}

.selectedCarProduct-no-categoryLink {
	margin-top: 40px;
}

.selectedCarProduct-categoryLink a:nth-child(1) {
	border-radius: 21px;
	background-color: var(--color-gray-blue);
	padding: 0 10px;
	text-decoration: none;
	text-align: center;
	min-width: 90px;
}

.selectedCarProduct-container label {
	color: var(--color-gray-dark);
	font-size: 14px;
}

.selectedCarProduct-categoryLink a:hover {
	background-color: var(--color-gray-blue-dark);
}

.selectedCarProduct-categoryLink>span {
	color: var(--color-gray);
	font-size: 12px;
	font-weight: 300;
}

.selectedCarProduct-categoryLink span:nth-child(1) {
	font-size: 12px;
	color: white;
	cursor: pointer;
	font-weight: 500;
}

.selectedCarProduct-categoryLink>hr {
	width: 20px;
	border: 0.1px solid;
	transform: rotate(90deg);
}

.selectedCarProduct-categoryLink img {
	height: 12.5px;
	margin: 0 7px;
	margin-top: 2px;
}

.selectedCarProduct-partNumber {
	color: var(--color-gray-blue);
	font-size: 12px;
	font-weight: 400;
	padding: 14px 0;
}

.selectedCarProduct-partNumber span {
	color: black;
}

.selectedCarProduct-prices-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
}

.selectedCarProduct-prices-container h4 {
	color: var(--color-gray-blue);
	font-size: 14px;
	font-weight: 400;
	margin: 0;
}

.selectedCarProduct-prices-container>span {
	font-size: 16px;
	margin-top: 16px;
}

.selectedCarProduct-title {
	font-size: 20px;
	font-weight: 300;
	margin: 0;
	padding: 0 10px;
}

.selectedCarProduct-subtitle {
	font-size: 16px;
	font-weight: 300;
	margin: 0;
	padding: 0 10px;
}

.selectedCarProduct-modal-container {
	position: absolute;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
}

.selectedCarProduct-modal-container img {
	cursor: pointer;
}


.selectedCarProduct-modal-container img:nth-of-type(2) {
	cursor: default;
	background-color: white;
}

.selectedCarProduct-modal-icons {
	display: flex;
	justify-content: space-between;
	padding: 20px;
}

.selectedCarProduct-modal-icons img {
	position: relative;
	z-index: 10;
	width: 13px;
}

.selectedCarProduct-modal-icons>div {
	color: white;
	font-size: 14px;
	font-weight: bold;
}

.selectedCarProduct-modal-image-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	justify-content: space-between;

	padding: 20px;
	width: 100%;
}

.selectedCarProduct-dollar-container {
	display: none;
}

.selectedCarProduct-complete-info {
	width: 330px;
}

.selectedCarProduct-zoom-container {
	margin: auto;
}

.selectedCarProduct-description-container {
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	padding: 2px 20px 20px;
	margin: 15px 0;
}

.selectedCarProduct-image-container-options {
	position: absolute;
	width: fit-content;
	z-index: 1000;
}

.selectedCarProduct-image-container>section {
	position: absolute;
	top: 230px;
	display: flex;
	align-items: center;
	gap: 15px;
	z-index: 100;
}

.selectedCarProduct-image-container section img {
	width: 30px;
	height: 30px;
	padding: 5px;
	border-radius: 5px;
	border: 1px solid var(--color-gray-blue);
	cursor: pointer;
}

.selectedCarProduct-image-container section img:hover {
	background: var(--color-gray-ligther);
}

.selectedCarProduct-quotationSelector-mobile {
	margin-bottom: 15px;
}

@media (min-width: 744px) and (max-width: 1297px) {
	.selectedCarProduct-container {
		width: 624px;
	}

	.selectedCarProduct-outdated>br {
		display: none;
	}

	.selectedCarProduct-image {
		max-width: 250px;
		max-height: 250px;
	}

	.selectedCarProduct-mainInfo-container section div {
		flex-direction: row;
		gap: 5px;
	}

	.selectedCarProduct-mainInfo-container section {
		font-size: 16px;
		width: 615px;
	}

	.selectedCarProduct-image-ul {
		gap: 50px;
	}

	.selectedCarProduct-complete-info {
		width: 617px;
	}

	.selectedCarProduct-images-li {
		height: 60px;
		width: 60px;
	}

	.selectedCarProduct-images-li img {
		max-height: 55px;
		max-width: 55px;
	}

	.selectedCarProduct-categoryLink>span {
		font-size: 15px;
	}

}

@media (min-width: 1298px) {
	.selectedCarProducts-selects {
		display: none;
	}

	.selectedCarProduct-container {
		width: 1298px;
	}

	.selectedCarProduct-container .menuItems-container {
		display: none;
	}

	.selectedCarProduct-mainInfo-container {
		min-height: 240px;
		padding: 5px 0 5px 5px;
	}

	.selectedCarProduct-image-container {
		padding: 80px 25px;
		min-height: 320px;
		width: 440px;
	}

	.selectedCarProduct-complete-info {
		display: flex;
		flex-direction: column;
		width: 900px;
	}

	.selectedCarProduct-info {
		width: 900px;
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.selectedCarProduct-info-container {
		padding: 20px 22px;
	}

	.selectedCarProduct-title {
		font-size: 1.25rem;
	}

	.selectedCarProduct-subtitle {
		font-size: 20px;
	}

	.selectedCarProduct-zoom-container {
		position: relative;
	}

	.selectedCarProduct-image {
		width: 100%;
		height: auto;
	}

	.selectedCarProduct-zoom-lens {
		width: 100px;
		height: 100px;
		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba(0, 0, 0, 0.38);
		cursor: zoom-in;
	}

	.selectedCarProduct-zoom-result {
		position: absolute;
		z-index: 2;
		width: 32vw;
		height: 32vw;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		background-repeat: no-repeat;
		background-color: white;
	}

	.selectedCarProduct-dollar-container {
		display: flex;
		flex-direction: column;
		margin-left: 40px;
		width: 282px;
		gap: 15px;
	}

	.selectedCarProduct-images-li {
		height: 50px;
		width: 50px;
	}

	.selectedCarProduct-images-li img {
		max-height: 45px;
		max-width: 45px;
	}

	.selectedCarProduct-image-ul {
		gap: 25px;
	}

	.selectedCarProduct-mainInfo-container section {
		width: 438px;
	}

	.selectedCarProduct-no-categoryLink,
	.selectedCarProduct-categoryLink {
		width: 1218px;
		margin: auto;
	}

	.selectedCarProduct-no-categoryLink {
		margin-top: 125px;
	}

	.selectedCarProducts-selects {
		display: none;
	}

	.selectedCarProduct-image-container>section {
		top: 190px !important;
	}

	.selectedCarProduct-dollar-container select {
		width: 283px;
	}

	.m-46b77525 label {
		font-size: 14px;
	}

	.selectedCarProduct-partNumber {
		margin: 22px 0;
	}

	.selectedCarProduct-description-title {
		font-size: 20px;
	}

	.selectedCarProduct-categoryLink>span {
		font-size: 15px;
	}

	.selectedCarProduct-quotationSelector-mobile {
		display: none;
	}
}