.carProductCard-container {
	text-decoration: none;
	color: inherit;
	display: flex;
	justify-content: center;

	overflow: hidden;
	flex-direction: column;
	background-color: white;
	border-radius: 0 10px 10px 10px;
	width: 318px;
	height: 100%;
	border: 1px solid var(--color-gray-ligth);
}

.carProductCard-timestamp {
	text-align: center;
	background-color: white;
	border: 1px solid var(--color-gray-ligth);
	border-bottom: none;
	border-radius: 5px 5px 0 0;
	width: 207px;
	line-height: 16px;
}

.carProductCard-timestamp span {
	color: var(--color-gray);
	font-size: 12px;
}

.carProductCard-container>div>div:first-of-type {
	width: 316px;
	height: 112px;
	overflow: hidden;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 0 5px 0 0;
}

.carProductCard-container>div>div:first-of-type img:nth-child(1) {
	width: 100%;
	position: relative;
	z-index: 1;
	opacity: 1;
	filter: blur(4px) brightness(50%);
}

.carProductCard-container>div>div:first-of-type img:nth-child(2) {
	position: absolute;
	z-index: 2;
	height: 112px;
}

.carProductCard-container>div>section:first-of-type {
	border-bottom: 1px solid var(--color-gray-ligth);
	border-top: 1px solid var(--color-gray-ligth);
	padding: 10px;
}

.carProductCard-container>div>section:first-of-type h3 {
	color: var(--color-gray-dark);
	font-size: 22px;
	height: 48px;
	margin: 0;
	text-transform: uppercase;
	overflow: hidden;
}

.carProductCard-container>div>section:first-of-type h4 {
	color: var(--color-gray);
	font-size: 14px;
	font-weight: 400;
	height: 30px;
	margin: 10px 0;
	overflow: hidden;
	text-transform: uppercase;
}

.carProductCard-container>div>section:first-of-type h5 {
	color: var(--color-gray);
	font-size: 14px;
	font-weight: 700;
	margin: 0;
	text-transform: capitalize;
}

.carProductCard-container>div>div:last-of-type {
	border-bottom: 1px solid var(--color-gray-ligth);
	padding: 10px;
}

.carProductCard-container>div>div:last-of-type span {
	color: var(--color-gray-dark);
	font-size: 22px;
	margin: 0;
}

.carProductCard-container>div>section:last-of-type {
	display: flex;
	justify-content: space-evenly;
}

.carProductCard-container>div>section:last-of-type h5 {
	color: var(--color-gray-dark);
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	padding: 10px;
	text-align: center;
}

.carProductCard-container>div>section:last-of-type h5:nth-child(2) {
	border-left: 1px solid var(--color-gray-ligth);
	/* border-right: 1px solid var(--color-gray-ligth); */
	width: 60%;
}

.carProductCard-container>div>section:last-of-type h5:nth-child(2).hasLabel {
	border-right: 1px solid var(--color-gray-ligth); 
}

.carProductCard-container>div>section:last-of-type img{
	width: 20px;
	height: 20px;
	margin-top: 10px;
	cursor: pointer;
}

.carProductCard-price-suggested{
	border-top: 2px solid var(--color-gray-blue);
	padding: 5px;
	border-radius: 0 0 10px 10px;
	font-size: 18px;
	font-weight: 500;
	color: var(--color-gray-blue);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.carProductCard-price-suggested h6{
	font-size: 14px;
	margin: 0;
}

.carProductCard-price-suggested span {
	font-weight: 600;
}