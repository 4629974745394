.quotation-quantity-span {
	color: white;
	background-color: var(--color-gray-blue);
	border-radius: 7px;
	padding: 4px 8px;
	height: fit-content;
	font-size: 12px;
	font-weight: 600;
	line-height: normal;
}

.selectedProduct-container {
	margin: auto;
	margin-top: 85px;
	width: 330px;
	margin-bottom: 20px;
}

.selectedProduct-dollar-container select {
	width: 328px;
	background-color: white;
	border-radius: 3px;
	color: var(--color-gray-dark);
	font-size: 14px;
	font-weight: 500;
}

.selectedProduct-container option {
	color: black;
	font-size: 14px;
}

.selectedProduct-outdated {
	color: var(--color-red);
	font-size: 12px;
	font-weight: 300;
	margin: 0;
}

.selectedProduct-description-info {
	font-size: 14px;
	font-weight: 300;
	text-align: justify;
	color: var(--color-gray-dark)
}

.selectedProduct-description-info p {
	max-height: calc(1.4em * 5);
	line-height: 1.4em;
	margin-bottom: 5px;
}

.selectedProduct-description-info div {
	display: flex;
	gap: 5px;
	margin-bottom: 20px;
	cursor: pointer;
}

.selectedProduct-description-info img {
	width: 8px;
}

.selectedProduct-description-info h6 {
	color: var(--color-gray-dark);
	font-size: 14px;
	margin: 0;
}

.selectedProduct-description-title {
	font-size: 16px;
	font-weight: 400;
	margin: 20px 0 10px;
}

.selectedProduct-options-title {
	font-size: 20px;
	font-weight: 500;
	margin: 0 20px;
	color: var(--color-gray-blue);
	margin-top: 12px;
}

.selectedProduct-image {
	max-width: 180px;
	max-height: 180px;
}

.selectedProduct-image-container {
	background-color: white;
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 40px 10px;
	width: 100%;
	border: 1px solid var(--color-gray-ligth);
	border-radius: 10px;
}

.selectedProduct-image-container img {
	cursor: pointer;
}

.selectedProduct-images-li {
	list-style-type: none;
	border: 1px solid var(--color-gray-ligth);
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 4px;
	height: 40px;
	width: 40px;
	padding: 2px;
	display: flex;
	justify-content: center;
}

.selectedProduct-images-li img {
	max-height: 35px;
	max-width: 35px;
	cursor: pointer;
}

.selectedProduct-image-ul {
	display: flex;
	padding: 0;
	gap: 14px;
}

.selectedProduct-info-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	text-align: center;
	margin-top: 10px;
	padding: 22px;
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	min-height: 90px;
}

.selectedProduct-info-container span {
	color: var(--color-gray);
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
}

.selectedProduct-info-container h4 {
	color: var(--color-gray-blue);
	font-size: 14px;
	font-weight: 600;
}

.selectedProduct-info-container>hr {
	width: 57.5px;
	transform: rotate(90deg);
	margin-left: -16px;
	margin-right: -16px;
}

.selectedProduct-link-container {
	display: flex;
	background-color: white;
	border: 1px solid var(--color-gray-blue);
	border-radius: 8px;
	margin-top: 15px;
	text-decoration: none;
	padding: 13px;
	width: 100%;
}


.selectedProduct-link-container:hover {
	background-color: var(--color-gray-blue-dark);
	border: 1px solid var(--color-gray-blue-dark);
}

.selectedProduct-link-container:hover>.selectedProduct-link-title {
	color: var(--color-gray-ligther);
}

.selectedProduct-link-title {
	color: var(--color-gray-blue);
	font-size: 12px;
	font-weight: bold;
	margin: 0;
	width: inherit;
	text-align: center;
}

.selectedProduct-mainInfo-container {
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 190px;
	margin-bottom: 15px;
	padding: 5px;
}

.selectedProduct-mainInfo-container>* {
	padding: 10px;
}

.selectedProduct-mainInfo-container section {
	border-top: 2px solid var(--color-gray-blue);
	padding: 5px;
	border-radius: 0 0 10px 10px;
	display: flex;
	justify-content: space-around;
	font-size: 16px;
	font-weight: 500;
	margin-top: -10px;
	width: 328px;
	color: var(--color-gray-blue);
	margin-left: -5px;
}

.selectedProduct-mainInfo-container section div span:nth-of-type(1) {
	font-weight: normal;
	font-size: 14px;
}

.selectedProduct-mainInfo-container section span {
	font-weight: 600;
}

.selectedProduct-mainInfo-container section div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.selectedProduct-no-categoryLink,
.selectedProduct-categoryLink {
	display: flex;
	align-items: center;
	gap: 7px;
	padding: 30px 0 20px 0;
}

.selectedProduct-no-categoryLink {
	margin-top: 40px;
}

.selectedProduct-categoryLink a:nth-child(1) {
	border-radius: 21px;
	background-color: var(--color-gray-blue);
	padding: 0 10px;
	text-decoration: none;
	text-align: center;
	min-width: 90px;
}

.selectedProduct-container label {
	color: var(--color-gray-dark);
	font-size: 14px;
}

.selectedProduct-categoryLink a:hover {
	background-color: var(--color-gray-blue-dark);
}

.selectedProduct-categoryLink>span {
	color: var(--color-gray);
	font-size: 12px;
	font-weight: 300;
}

.selectedProduct-categoryLink span:nth-child(1) {
	font-size: 12px;
	color: white;
	cursor: pointer;
	font-weight: 500;
}

.selectedProduct-categoryLink>hr {
	width: 20px;
	border: 0.1px solid;
	transform: rotate(90deg);
}

.selectedProduct-categoryLink img {
	height: 12.5px;
	margin: 0 7px;
	margin-top: 2px;
}

.selectedProduct-partNumber {
	color: var(--color-gray-blue);
	font-size: 12px;
	font-weight: 400;
	padding: 14px 0;
}

.selectedProduct-partNumber span {
	color: black;
}

.selectedProduct-prices-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
}

.selectedProduct-prices-container h4 {
	color: var(--color-gray-blue);
	font-size: 14px;
	font-weight: 400;
	margin: 0;
}

.selectedProduct-prices-container h6{
	color: var(--color-gray);
	font-size: 14px;
}

.selectedProduct-prices-container>span {
	font-size: 16px;
	margin-top: 16px;
}

.selectedProduct-stock-container-noStock,
.selectedProduct-stock-container {
	border: 2px solid var(--color-gray-blue-dark);
	border-radius: 8px;
	padding: 14px;
	color: var(--color-gray-blue);
	display: flex;
	font-size: 10px;
	font-weight: 300;
	gap: 5px;
	margin: 23px 0;
	background-color: white;
}

.selectedProduct-stock-container-noStock {
	border: 2px solid var(--color-gray-blue-ligth);
}

.selectedProduct-stock-unit {
	width: 100%;
	justify-content: center;
	text-align: center;
	font-size: 13px;
}

.selectedProduct-stock-title-noStock,
.selectedProduct-stock-title {
	background-color: var(--color-gray-blue-dark);
	border-radius: 8px;
	font-size: 14px;
	margin: 0 0 -33px 10px;
	padding: 2px 10px 5px 10px;
	text-align: center;
	width: fit-content;
	color: white;
}

.selectedProduct-stock-title-noStock {
	background-color: var(--color-gray-blue-ligth);
}

.selectedProduct-stock-complete {
	color: var(--color-gray-blue-dark);
	font-weight: normal;
	padding: 0 10px;
	font-size: 13px;
}

.selectedProduct-title {
	font-size: 20px;
	font-weight: 300;
	margin: 0;
}

.selectedProduct-modal-container {
	position: absolute;
	height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
}

.selectedProduct-modal-container img {
	cursor: pointer;
}


.selectedProduct-modal-container img:nth-of-type(2) {
	cursor: default;
	background-color: white;
}

.selectedProduct-modal-icons {
	display: flex;
	justify-content: space-between;
	padding: 20px;
}

.selectedProduct-modal-icons img {
	position: relative;
	z-index: 10;
	width: 13px;
}

.selectedProduct-modal-icons>div {
	color: white;
	font-size: 14px;
	font-weight: bold;
}

.selectedProduct-modal-image-container {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	display: flex;
	justify-content: space-between;

	padding: 20px;
	width: 100%;
}

.selectedProduct-dollar-container {
	display: none;
}

.selectedProduct-complete-info {
	width: 330px;
}

.selectedProduct-zoom-container {
	margin: auto;
}

.selectedProduct-description-container {
	background-color: white;
	border-radius: 10px;
	border: 1px solid var(--color-gray-ligth);
	padding: 2px 20px 20px;
	margin: 15px 0;
}

.selectedProduct-image-container-options {
	position: absolute;
	width: fit-content;
	z-index: 1000;
}

.selectedProduct-image-container>section {
	position: absolute;
	top: 350px;
	display: flex;
	align-items: center;
	gap: 15px;
	z-index: 100;
}

.selectedProduct-quotationSelector-mobile {
	margin-bottom: 15px;
}

@media (min-width: 744px) and (max-width: 1297px) {
	.selectedProduct-container {
		width: 624px;
	}

	.selectedProduct-outdated>br {
		display: none;
	}

	.selectedProduct-image {
		max-width: 250px;
		max-height: 250px;
	}

	.selectedProduct-mainInfo-container section div {
		flex-direction: row;
		gap: 5px;
	}

	.selectedProduct-mainInfo-container section {
		font-size: 16px;
		width: 615px;
	}

	.selectedProduct-image-ul {
		gap: 50px;
	}

	.selectedProduct-complete-info {
		width: 617px;
	}

	.selectedProduct-images-li {
		height: 60px;
		width: 60px;
	}

	.selectedProduct-images-li img {
		max-height: 55px;
		max-width: 55px;
	}

	.selectedProduct-categoryLink>span {
		font-size: 15px;
	}

}

@media (min-width: 1298px) {
	.selectedProduct-container {
		width: 1298px;
	}

	.selectedProduct-container .menuItems-container {
		display: none;
	}

	.selectedProduct-mainInfo-container {
		min-height: 240px;
		padding: 5px 0 5px 5px;
	}

	.selectedProduct-image-container {
		padding: 80px 25px;
		min-height: 320px;
		width: 440px;
	}

	.selectedProduct-complete-info {
		display: flex;
		flex-direction: column;
		width: 900px;
	}

	.selectedProduct-info {
		width: 900px;
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.selectedProduct-info-container {
		padding: 20px 22px;
	}

	.selectedProduct-stock-title {
		font-size: 0.7rem;
	}

	.selectedProduct-title {
		font-size: 1.25rem;
	}

	.selectedProduct-zoom-container {
		position: relative;
	}

	.selectedProduct-image {
		width: 100%;
		height: auto;
	}

	.selectedProduct-zoom-lens {
		width: 100px;
		height: 100px;
		position: absolute;
		top: 0;
		left: 0;

		background-color: rgba(0, 0, 0, 0.38);
		cursor: zoom-in;
	}

	.selectedProduct-zoom-result {
		position: absolute;
		z-index: 2;
		width: 32vw;
		height: 32vw;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		background-repeat: no-repeat;
		background-color: white;
	}

	.selectedProduct-dollar-container {
		display: flex;
		flex-direction: column;
		margin-left: 40px;
		width: 282px;
		gap: 15px;
	}

	.selectedProduct-images-li {
		height: 50px;
		width: 50px;
	}

	.selectedProduct-images-li img {
		max-height: 45px;
		max-width: 45px;
	}

	.selectedProduct-image-ul {
		gap: 25px;
	}

	.selectedProduct-mainInfo-container section {
		width: 438px;
	}

	.selectedProduct-no-categoryLink,
	.selectedProduct-categoryLink {
		width: 1218px;
		margin: auto;
	}

	.selectedProduct-no-categoryLink {
		margin-top: 125px;
	}

	.selectedProducts-selects {
		display: none;
	}

	.selectedProduct-image-container>section {
		top: 280px !important;
	}

	.selectedProduct-dollar-container select {
		width: 283px;
	}

	.m-46b77525 label {
		font-size: 14px;
	}

	.selectedProduct-partNumber {
		margin: 22px 0;
	}

	.selectedProduct-description-title {
		font-size: 20px;
	}

	.selectedProduct-categoryLink>span {
		font-size: 15px;
	}

	.selectedProduct-quotationSelector-mobile {
		display: none;
	}
}