.entityLabels-container{
    width: 330px;
    margin: 0 0 40px 0;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.entityLabels-label{
    background-color: var(--color-gray-blue-dark);
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
}

.entityLabels-label h4{
    margin: 0;
    font-size: 14px;
    color: white;
    text-transform: capitalize;
}

.entityLabels-label img{
    height: 14px;
    width: 14px;
    cursor: pointer;
}